import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './AuthSlice';

const reducer = combineReducers({
  auth: authReducer
});

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.REACT_APP_ENV === 'local'
});
